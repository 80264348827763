<template>
  <div>
    <b-modal
      id="new-Edit-FuelRate-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-Edit-FuelRate-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-Edit-FuelRate-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Edit Fuel Rate</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit="editSaveFuel" @reset="resetForm">
        <div class="row py-4">
          <div class="col md-6">
            <label class="bold-label" for="Enter-Fuel-Rate"
              >Enter Fuel Rate</label
            >
            <b-form-input
              required
              type="number"
              step="0.01"
              min="0.00"
              placeholder=""
              class="b-form-r-margin"
              v-model="form.operatingMargin"
            ></b-form-input>
          </div>
        </div>
        <div class="row d-flex justify-content-end p-4">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Save
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
              v-if="processingRequest !== ''"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      form: {
        name: "",
        operatingMargin: "",
      },
      processingRequest: "",
      hasError: false,
      processingError: "",
    };
  },
  mounted() {
    this.form.operatingMargin = this.currentfuelrate;
  },
  props: {
    editToFuelRate: String,
    currentfuelrate: Number,
  },
  methods: {
    showModal: function () {
      this.$bvModal.show("new-Edit-FuelRate-modal");
    },
    closeModal: function () {
      this.$bvModal.hide("new-Edit-FuelRate-modal");
    },
    editSaveFuel: async function (e) {
      e.preventDefault();
      this.processingRequest = "processing";
      console.log("check edit Fuel Rate save log");
      let prevFR = this.currentfuelrate;

      if (this.form.operatingMargin !== "") {
        if (this.form.operatingMargin !== prevFR) {
          prevFR = this.form.operatingMargin;

          let fuelRateToUpdate = {
            value: parseFloat(this.form.operatingMargin),
            name: "fuelRate",
          };

          ApiService.post("/settings/editSettingByName", fuelRateToUpdate)
            .then((res) => {
              if (res.data.success) {
                this.$emit("newFuelRate-update", fuelRateToUpdate.value);
                this.resetForm();
              } else {
                this.hasError = "d-flex";
                this.processingError = res.data.error.message;
                this.resetForm();
                setTimeout(() => {
                  this.hasError = "d-none";
                  this.processingError = "";
                }, 3000);
              }
            })
            .catch(({ data }) => {
              this.hasError = "d-flex";
              this.processingError =
                "Error while updating user info.<br/>" +
                data.message +
                " on " +
                data.url;
              this.processingRequest = "";
              setTimeout(() => {
                this.hasError = "d-none";
                this.processingError = "";
              }, 3000);
              this.resetForm();
            })
            .catch(() => {
              this.processingRequest = "";
              ApiService.setHeader("content-type", "application/json");
              this.resetForm();
            });
        } else {
          this.resetForm();
          console.log("do nothing Since Fuel Rate have not been changed");
        }
      }

      //   setTimeout(() => {

      //   }, 10000);
    },
    resetForm: function () {
      console.log(
        this.form.operatingMargin + " = Operating Margin value checking"
      );
      //   const defaultForm = {
      //     name: "",
      //     operatingMargin: "",
      //   };
      //   for (let key of Object.keys(defaultForm)) {
      //     this.form[key] = defaultForm[key];
      //
      //   }
      // this.form.operatingMargin
      this.processingRequest = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}

::v-deep .modal-dialog {
  width: 600px;
  .new-Edit-FuelRate-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
    }
    .input-row {
      padding: 10px 0;
    }
    .bold-label {
      width: 100%;
      padding: 15px;
      display: flex;
      //   justify-content: left;
      margin-left: 0;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .b-form-r-margin {
      margin-left: 2.5%;
    }
  }
}
</style>
