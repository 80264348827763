<template>
  <div class="payments-block w-100">
    <div class="card payments-block__card">
      <div
        class="card-body d-flex flex-column align-items-center justify-content-center"
      >
        <div class="edit-container">
          <button
            variant="danger"
            class="create-button create-button--top"
            @click="changeValue"
          >
            {{ canEdit ? "Edit" : "Close" }}
          </button>
        </div>
        <img
          class="payments-block__image"
          src="/media/local/outgoing-icon.png"
          alt="Incoming Icon"
        />
        <div class="payments-block__text">
          {{ name }}
        </div>
        <template v-if="!canEdit">
          <form @submit.prevent="editValue">
            <div class="col-md-12 py-2">
              <b-form-input
                required
                v-model="changedValue"
                placeholder="Acceptable Distance"
                type="number"
                :min="50"
                :max="10000"
              ></b-form-input>
            </div>
            <div class="col-md-12 py-2">
              <b-form-checkbox
                id="update-stores"
                v-model="updateStores"
                name="updateStores"
                :value="true"
                :unchecked-value="false"
              >
                Update all stores as well
              </b-form-checkbox>
            </div>
            <div class="row d-flex justify-content-end p-4 align-items-center">
              <span class="process-status"> {{ processingStatusText }}</span>
              <b-button
                variant="danger"
                type="Submit"
                class="create-button"
                :class="processingRequest"
                :disabled="processingRequest !== ''"
                >Update
                <img
                  class="ajax-loader"
                  src="/media/ajax/white.gif"
                  alt="Ajax loader"
              /></b-button>
            </div>
          </form>
        </template>
        <template v-else>
          <div class="payments-block__value">{{ value }}</div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "FuelSettingsUS",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "Name",
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      changedValue: this.value,
      canEdit: true,
      processingStatusText: "",
      processingRequest: "",
      updateStores: false,
    };
  },
  mounted() {
    this.changedValue = this.value;
  },
  methods: {
    changeValue() {
      this.canEdit = !this.canEdit;
      this.changedValue = this.value;
    },
    editValue() {
      const { value, changedValue, id } = this;
      if (value !== changedValue) {
        this.processingRequest = "processing";
        ApiService.post("/settings/editSetting", {
          id,
          value: parseInt(changedValue, 10),
          udpateStores: this.updateStores,
        }).then(() => {
          this.$emit("getUpdate");
          this.processingRequest = "";
          this.canEdit = !this.canEdit;
        });
      }
    },
  },
};
</script>
<style lang="scss">
.btn-img {
  width: 20px;
  height: auto;
}
.edit-container {
  position: absolute;
  top: 0;
  right: 0;
}
.payments-block {
  position: relative;
  width: 185px;
  min-height: 150px;
  margin-right: 10px;
  &__card {
    border-radius: 15px;
    width: 100%;
  }
  &__image {
    margin: 10px auto;
    height: auto;
    width: 85px;
  }
  &__text {
    text-align: center;
    font-size: 18px;
    text-transform: capitalize;
    color: #74bbdb;
    font-weight: 600;
  }
  &__value {
    text-align: center;
    font-size: 14px;
  }
}
</style>
