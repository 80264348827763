<template>
  <tr>
    <td>{{ city.city }}</td>
    <td>
      <span :class="city.operational ? 'text-success' : 'text-danger'">{{
        city.operational ? "Yes" : "No"
      }}</span>
    </td>
    <td>
      <template v-if="!editSalesTax">
        <u
          @click="() => (this.editSalesTax = !this.editSalesTax)"
          class="text-block cursor-pointer"
          >{{ city.salesTax }}</u
        >
      </template>
      <template v-else>
        <form class="form form-inline" @submit.prevent="zipCodeSubmit">
          <b-form-input
            type="number"
            :required="true"
            v-model="editSalesTaxNumber"
            step="0.001"
            :disabled="updating"
          />
          <button
            class="create-button"
            :class="updating ? 'create-button--disabled' : ''"
            type="submit"
            :disabled="updating"
          >
            <i class="fa fa-save text-light"></i>
          </button>
          <button
            class="create-button"
            type="button"
            :class="updating ? 'create-button--disabled' : ''"
            @click="() => (this.editSalesTax = !this.editSalesTax)"
          >
            <i class="fa fa-times text-light"></i>
          </button>
        </form>
      </template>
    </td>
    <td>
      <button
        class="create-button"
        @click="operationalUpdate"
        :disabled="updating"
        :class="updating ? 'create-button--disabled' : ''"
      >
        {{ city.operational ? "Make inactive" : "Make Active" }}
      </button>
    </td>
  </tr>
</template>

<script>
import { UPDATE_ZIPCODE } from "@/core/services/store/settings-us.module";
export default {
  name: "ActiveZipcodeCity",
  props: {
    city: {
      type: Object,
      required: true,
    },
    zipCode: String,
  },
  data() {
    return {
      editSalesTax: false,
      editSalesTaxNumber: this.city.salesTax,
      updating: false,
    };
  },
  methods: {
    async zipCodeSubmit() {
      this.updating = true;
      const result = await this.$store.dispatch(UPDATE_ZIPCODE, {
        zipCode: this.zipCode,
        cityId: this.city.id,
        salesTax: this.editSalesTaxNumber,
        editing: true,
      });

      this.updating = false;
      if (!result.error) {
        this.editing = false;
      } else {
        this.errorMsg = result.error;
        setTimeout(() => {
          this.errorMsg = "";
        }, 2500);
      }
    },
    async operationalUpdate() {
      console.log("here");
      this.updating = true;
      const result = await this.$store.dispatch(UPDATE_ZIPCODE, {
        zipCode: this.zipCode,
        cityId: this.city.id,
        operational: !this.city.operational,
        editing: true,
      });

      this.updating = false;
      if (!result.error) {
        this.editing = false;
      } else {
        this.errorMsg = result.error;
        setTimeout(() => {
          this.errorMsg = "";
        }, 2500);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
