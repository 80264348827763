<template>
  <div class="settings">
    <div class="container">
      <div class="row">
        <div class="col-md-6 py-3">
          <h3>Setting</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="acceptableRange.id !== 0">
        <fuel-card
          name="Default Range"
          :id="acceptableRange.id"
          :value="acceptableRange.value"
          @getUpdate="getUpdate"
        />
      </div>
      <div class="col-12">
        <up-charge />
      </div>
      <div class="col-12">
        <active-zipcodes />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_ACCETABLE_RANGE } from "@/core/services/store/settings-us.module";
import FuelCard from "@/view/pages/settings/us/FuelCard";
import UpCharge from "@/view/pages/settings/us/UpCharge";
import ActiveZipcodes from "@/view/pages/settings/us/ActiveZipcodes";
export default {
  name: "SettingsUS",
  components: {
    FuelCard,
    UpCharge,
    ActiveZipcodes,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      acceptableRange: ({ settingsUs: settings }) => settings.acceptableRange,
    }),
  },
  methods: {
    getUpdate() {
      this.$store.dispatch(GET_ACCETABLE_RANGE);
    },
  },
  mounted() {
    this.getUpdate();
  },
};
</script>

<style lang="scss">
.settings {
}
</style>
