<template>
  <div class="container settings">
    <div class="row">
      <div class="col-6">
        <div class="card mx-auto shadow">
          <span class="edit-pencil">
            <img
              @click="editFuelRate"
              class="edit-pencil__image"
              src="/media/settings/edit_pencil.png"
            />
          </span>
          <div class="d-flex flex-column align-items-center">
            <img
              class="settings__fuel-image"
              src="/media/settings/settings_fuel.png"
            />
          </div>
          <h2 class="settings__blue-heading text-center">Fuel Rate</h2>
          <p class="settings__text text-center">{{ this.fuelRate }} Rs/km</p>
        </div>
      </div>
      <div class="col-6">
        <div class="card mx-auto shadow">
          <div class="unv-dc">
            <h3>Universal Delivery Charges</h3>
            <span class="edit-pencil">
              <img
                @click="editUDC"
                class="edit-pencil__image"
                src="/media/settings/edit_pencil.png"
              />
            </span>
          </div>
          <ul class="settings__charges-list">
            <template v-for="charge in charges">
              <li class="settings__charges-list__item" :key="charge.type">
                <div class="settings__charges row">
                  <div class="col-6">
                    <div
                      :class="`settings__charges__type settings__charges__type--${charge.type}`"
                    >
                      {{ charge.type }} Zone
                    </div>
                  </div>
                  <div class="col-6 settings__charges__value text-center">
                    <div
                      class="settings__charges__value__text settings__charges__value__text--light"
                    >
                      Charges
                    </div>
                    <div class="settings__charges__value__text">
                      Rs. {{ charge.value }}
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>

    <template v-if="UniversalDCtoEdit !== null">
      <EditUniDChrarges
        ref="editUnivDChargesModal"
        :editToUDC="UniversalDCtoEdit"
        :chargestPass="charges"
        @newUDC-update="updateNewUDC"
      />
    </template>
    <template v-if="fuelRatetoEdit !== null">
      <EditFuelRate
        ref="editFuelRateModalref"
        :editToFuelRate="fuelRatetoEdit"
        :currentfuelrate="fuelRate"
        @newFuelRate-update="updateNewFuelrate"
      />
    </template>
    <div class="row">
      <div class="col-12">
        <reports />
      </div>
    </div>
  </div>
</template>

<script>
import Reports from "@/view/pages/settings/pk/Reports";
import EditUniDChrarges from "@/view/pages/settings/EditUnivDCharges";
import EditFuelRate from "@/view/pages/settings/EditFuelRate";
import ApiService from "@/core/services/api.service";

export default {
  name: "SettingsPk",
  components: {
    EditUniDChrarges,
    EditFuelRate,
    Reports,
  },
  data() {
    return {
      UniversalDCtoEdit: null,
      fuelRatetoEdit: null,
      fuelRate: 3,
      charges: [
        {
          type: "primary",
          value: 0,
        },
        {
          type: "secondary",
          value: 0,
        },
        {
          type: "others",
          value: 0,
        },
      ],
      stats: {
        message: "",
        settings: {
          _id: "",
          __v: 0,
          customerDeliveryCharges: {
            primaryZone: 0,
            secondaryZone: 0,
            others: 0,
          },
          fuelRate: 0,
          settingName: "",
        },
      },
    };
  },

  mounted() {
    ApiService.get("/settings/getLocalSettings")
      .then(({ data: response }) => {
        if (response.success) {
          const data = {
            message: response.message,
            settings: response.settings,
          };
          this.stats = data;
          this.fuelRate = this.stats.settings.find(
            (s) => s.settingName === "fuelRate"
          ).settingValue;
          this.charges[0].value = this.stats.settings.find(
            (s) => s.settingName === "primaryZoneCharges"
          ).settingValue;
          this.charges[1].value = this.stats.settings.find(
            (s) => s.settingName === "secondaryZoneCharges"
          ).settingValue;
          this.charges[2].value = this.stats.settings.find(
            (s) => s.settingName === "othersZoneCharges"
          ).settingValue;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    editUDC: function () {
      this.UniversalDCtoEdit = "umar";
      setTimeout(() => {
        this.$refs.editUnivDChargesModal.showModal();
      }, 100);
    },
    editFuelRate: function () {
      this.fuelRatetoEdit = "testHello";

      setTimeout(() => {
        this.$refs.editFuelRateModalref.showModal();
      }, 100);
    },
    async updateNewFuelrate(newFuelRate) {
      this.fuelRate = newFuelRate;
      this.$refs.editFuelRateModalref.closeModal();

      setTimeout(() => {
        this.fuelRatetoEdit = null;
      }, 100);
    },
    updateNewUDC(request) {
      switch (request.name) {
        case "primaryZoneCharges":
          this.charges[0].value = request.value;
          break;
        case "secondaryZoneCharges":
          this.charges[1].value = request.value;
          break;
        case "othersZoneCharges":
          this.charges[2].value = request.value;
          break;
        default:
          console.error("unknown condition.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 400px;
  height: 400px;
  padding: 20px 30px;
}
.edit-pencil {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  &__image {
    width: 20px;
    height: auto;
  }
  &:active {
    transform: scale(0.85);
  }
}
.settings {
  &__fuel-image {
    height: 240px;
    width: 240px;
    margin: 10px;
  }
  &__blue-heading {
    color: #74bfe8;
    margin: 10px;
    font-weight: 600;
  }
  &__text {
    font-size: 18px;
  }
  &__charges-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &__item {
      margin-top: 20px;
    }
  }
  &__charges {
    display: flex;
    justify-content: space-between;
    &__type {
      width: 100%;
      display: grid;
      padding: 10px;
      place-items: center;
      font-size: 16px;
      font-weight: 600;

      &--primary {
        color: #74bfe8;
        background: #74bfe832;
      }
      &--secondary {
        color: #8fbc00;
        background: #f8ff6f;
      }
      &--others {
        color: #f0002a;
        background: #f0002a32;
      }
    }
    &__value {
      margin-top: auto;
      margin-bottom: auto;
      &__text {
        font-size: 12px;
        font-weight: 600;
        &--light {
          font-size: 14px;
          font-weight: 400;
          color: #4a4a4a;
        }
      }
    }
  }
}
</style>
