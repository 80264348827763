<template>
  <div>
    <b-modal
      id="new-Edit-UDC-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-Edit-UDC-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-Edit-UDC-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Edit Universal Delivery Charges</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit="editSaveUDC" @reset="resetForm">
        <div class="input-row">
          <span class="bold-label">Select Zone {{ this.form.userType }}</span>

          <b-form-radio-group
            id="zone-Type"
            :options="zoneOptionRadio"
            v-model="form.userType"
            @change="changingvaluesofZone"
            name="zone-Type"
            class="b-form-r-margin"
          ></b-form-radio-group>
        </div>
        <div class="row py-4">
          <div class="col md-6">
            <label class="bold-label" for="Enter-Charges">Enter Charges</label>
            <b-form-input
              required
              type="number"
              placeholder=""
              class="b-form-r-margin"
              v-model="form.operatingMargin"
            ></b-form-input>
          </div>
        </div>
        <div class="row d-flex justify-content-end p-4">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Save
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
              v-if="processingRequest !== ''"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      form: {
        name: "",
        operatingMargin: this.chargestPass[0].value,
        userType: "Primary",
      },
      eligibletoEditBool: false,
      processingRequest: "",
      zoneOptionRadio: [
        { text: "Primary Zone", value: "Primary" },
        { text: "Secondary Zone", value: "Secondary" },
        { text: "Other Zone", value: "Other" },
      ],
      hasError: "",
      processingError: "",
    };
  },
  props: {
    editToUDC: String,
    chargestPass: Array,
  },
  methods: {
    showModal: function () {
      this.$bvModal.show("new-Edit-UDC-modal");
    },
    closeModal: function () {
      this.$bvModal.hide("new-Edit-UDC-modal");
    },
    editSaveUDC: async function (e) {
      e.preventDefault();
      this.processingRequest = "processing";
      // let toUpdateZone=this.form.userType
      const request = {};

      switch (this.form.userType) {
        case "Primary":
          if (this.form.operatingMargin !== this.chargestPass[0].value) {
            this.eligibletoEditBool = true;
            request.value = parseInt(this.form.operatingMargin);
            request.name = "primaryZoneCharges";
          }
          break;
        case "Secondary":
          if (this.form.operatingMargin !== this.chargestPass[1].value) {
            this.eligibletoEditBool = true;
            request.value = parseInt(this.form.operatingMargin);
            request.name = "secondaryZoneCharges";
          }
          break;
        case "Other":
          if (this.form.operatingMargin !== this.chargestPass[2].value) {
            this.eligibletoEditBool = true;
            request.value = parseInt(this.form.operatingMargin);
            request.name = "othersZoneCharges";
          }
          break;
      }

      if (
        this.form.operatingMargin !== "" &&
        this.eligibletoEditBool === true
      ) {
        ApiService.post("/settings/editSettingByName", request)
          .then((res) => {
            if (res.data.success) {
              this.$emit("newUDC-update", request);
              // this.resetForm();
              setTimeout(() => {}, 100);
              this.processingRequest = "";
              this.eligibletoEditBool = false;
            } else {
              this.hasError = "d-flex";
              this.processingError = "Unknown error while updating vendor.";
              this.resetForm();
              setTimeout(() => {
                this.hasError = "d-none";
                this.processingError = "";
              }, 3000);
            }
          })
          .catch(({ data }) => {
            this.hasError = "d-flex";
            this.processingError =
              "Error while updating user info.<br/>" +
              data.message +
              " on " +
              data.url;

            setTimeout(() => {
              this.hasError = "d-none";
              this.processingError = "";
            }, 3000);
            this.resetForm();
          })
          .catch(() => {
            ApiService.setHeader("content-type", "application/json");
            this.resetForm();
          });

        // this.resetForm();
      } else {
        this.processingRequest = "";
        this.eligibletoEditBool = false;
      }
    },
    changingvaluesofZone() {
      switch (this.form.userType) {
        case "Primary":
          this.form.operatingMargin = this.chargestPass[0].value;
          break;
        case "Secondary":
          this.form.operatingMargin = this.chargestPass[1].value;
          break;
        case "Other":
          this.form.operatingMargin = this.chargestPass[2].value;
          break;
      }
    },
    resetForm: function () {
      const defaultForm = {
        name: "",
        operatingMargin: this.chargestPass[0].value,
        userType: "Primary",
      };
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
      this.processingRequest = "";
      this.eligibletoEditBool = false;

      this.zoneOptionRadio = [
        { text: "Primary Zone", value: "Primary" },
        { text: "Secondary Zone", value: "Secondary" },
        { text: "Other Zone", value: "Other" },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}

::v-deep .modal-dialog {
  width: 600px;
  .new-Edit-UDC-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
    }
    .input-row {
      padding: 10px 0;
    }
    .bold-label {
      width: 100%;
      padding: 15px;
      display: flex;
      //   justify-content: left;
      margin-left: 0;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .b-form-r-margin {
      margin-left: 2.5%;
    }
  }
}
</style>
