<template>
  <div class="card w-100 m-3">
    <h3 class="mb-2">Order Repots</h3>
    {{ createReportStatus }}
    <form @submit.prevent="generateReport" class="form form-inline">
      <div class="form-group mx-2">
        <label for="from-datepicker" class="mr-3 font-weight-bold">From</label>
        <b-form-datepicker
          id="from-datepicker"
          :min="minDate"
          :max="today"
          v-model="from"
          class="mb-2"
          :disabled="loading"
          @input="fromChanged"
        ></b-form-datepicker>
      </div>
      <div class="form-group mb-2">
        <label for="from-datepicker" class="mr-3 font-weight-bold">To</label>
        <b-form-datepicker
          id="to-datepicker"
          v-model="to"
          :min="toMin"
          :max="today"
          :disabled="loading"
          class="mb-2"
        ></b-form-datepicker>
      </div>
      <div class="form-group align-items-center d-flex h-100">
        <button
          type="submit"
          :disabled="loading"
          :class="loading ? 'create-button--disabled' : ''"
          class="ml-4 create-button"
        >
          Generate new
        </button>
      </div>
    </form>
    <ul class="list-group">
      <template v-for="report in reports">
        <li class="list-group-item" :key="report._id">
          <div class="d-flex w-100 justify-content-between align-items-center">
            <span>
              {{ $moment(report.from).format("MMM/DD/YY") }} -
              {{ $moment(report.to).format("MMM/DD/YY") }}</span
            >
            <a :href="report.reportUrl" class="create-button">Download</a>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import {
  GET_REPORTS,
  CREATE_REPORT,
} from "@/core/services/store/settings.module";
import { mapState, mapActions } from "vuex";
export default {
  name: "OrderReportsPk",
  data() {
    return {
      createReportStatus: "",
      from: new Date(),
      to: new Date(),
      toMin: new Date(),
      today: new Date(),
      minDate: new Date(),
      loading: false,
    };
  },
  mounted() {
    this.getReports({ offset: 0, limit: 25 });
    const minDate = new Date("1 Jan 2022");
    this.minDate = minDate;
    this.from = minDate;
    this.to = new Date();
    this.today = new Date();
    this.toMin = minDate;
  },
  computed: {
    ...mapState({
      reports: ({ settingsPk }) => {
        return settingsPk.reports;
      },
    }),
  },
  methods: {
    ...mapActions({
      getReports: GET_REPORTS,
      createReport: CREATE_REPORT,
    }),
    fromChanged(val) {
      this.toMin = new Date(val);
    },
    async generateReport() {
      this.loading = true;
      const request = {
        from: this.$moment(this.from).unix() * 1000,
        to: this.$moment(this.to).unix() * 1000,
      };
      await this.createReport(request);
    },
  },
};
</script>

<style lang="scss" scoped></style>
