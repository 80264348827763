<template>
  <div class="row">
    <div
      class="col-md-12 d-grid place-center text-danger p-3"
      v-if="errorMsg !== ''"
    >
      {{ errorMsg }}
    </div>
    <template v-if="!setting">
      <form class="w-100 form" @submit.prevent="createNewZipcode">
        <div class="row">
          <div class="col-8">
            <b-form-input
              v-model="newZipCode"
              type="text"
              :required="true"
              placeholder="Add a new zipcode"
            />
          </div>
          <div class="col-4 place-center">
            <button class="create-button" type="submit">Add New</button>
          </div>
        </div>
      </form>
    </template>
    <template v-else>
      <div class="card w-100">
        <div class="card-body">
          <button class="create-button" @click="toggleOpen">
            {{ setting.zipCode }}
            <i class="ml-4 fa fa-chevron-right text-light" v-if="!open"></i>
            <i class="ml-4 fa fa-chevron-left text-light" v-if="open"></i>
          </button>
          <template v-if="open">
            <div class="row p-3">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th>City</th>
                    <th>Operational</th>
                    <th>Sales Tax</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <template v-for="city in setting.cities">
                  <active-zipcode-city
                    :city="city"
                    :key="city.id + city.operational + city.salesTax"
                    :zipCode="setting.zipCode"
                  />
                </template>
              </table>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  UPDATE_ZIPCODE,
  // REMOVE_ZIPCODE
} from "@/core/services/store/settings-us.module";
import ActiveZipcodeCity from "@/view/pages/settings/us/ActiveZipcodeCity";
export default {
  name: "ActiveZipCodeSingleItem",
  props: {
    setting: {
      type: Object,
      default: null,
      required: false,
    },
  },
  components: {
    ActiveZipcodeCity,
  },
  data() {
    return {
      open: false,
      newZipCode: null,
      updating: false,
      errorMsg: "",
    };
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
    async createNewZipcode() {
      this.updating = true;
      const result = await this.$store.dispatch(UPDATE_ZIPCODE, {
        zipCode: this.newZipCode,
      });
      this.updating = false;
      if (result.error !== null) {
        this.errorMsg = result.error;
        setTimeout(() => {
          this.errorMsg = "";
        }, 2500);
      } else {
        this.newZipCode = "";
      }
    },

    cancelEditing() {
      this.editing = false;
      setTimeout(() => {
        this.setDefaults();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped></style>
