<template>
  <div>
    <settings-pk v-if="region === 'PAKISTAN'" />
    <settings-us v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SettingsPk from "@/view/pages/settings/pk/Settings";
import SettingsUs from "@/view/pages/settings/us/Settings";

export default {
  name: "SettingsMain",
  components: {
    SettingsPk,
    SettingsUs,
  },
  computed: {
    ...mapState({
      region: ({ region }) => region.region,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
